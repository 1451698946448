export default [
  {
    header: 'breadcrumb.marketplace',
    resource: 'marketplace-header',
    action: 'read',
  },
  {
    title: 'breadcrumb.vehicle',
    icon: 'PackageIcon',
    children: [
      {
        title: 'breadcrumb.brand',
        route: 'list-vehicle-brand',
        icon: '',
        resource: 'vehicle-brand',
        action: 'read'
      },
      {
        title: 'breadcrumb.color',
        route: 'list-vehicle-color',
        icon: '',
        resource: 'vehicle-color',
        action: 'read'
      },
      {
        title: 'breadcrumb.fuelType',
        route: 'list-vehicle-fuel-type',
        icon: '',
        resource: 'vehicle-fuel-type',
        action: 'read'
      },
      {
        title: 'breadcrumb.vehicle',
        route: 'list-vehicle',
        icon: '',
        resource: 'vehicle',
        action: 'read'
      },
    ],
  },
];