import dashboard from "./menu/dashboard";
import marketplaceHeader from "./menu/marketplaceHeader";
import configurationHeader from "./menu/configurationHeader";
import vehicle from "./menu/vehicle";
import setting from "./menu/setting";
import user from "./menu/user";
import dealer from "./menu/dealer";
import auctionSchedule from "./menu/auctionSchedule";

export default [
  ...dashboard,
  ...marketplaceHeader,
  ...vehicle,
  ...auctionSchedule,
  ...configurationHeader,
  ...dealer,
  ...setting,
  ...user,
]
