export default [
  {
    title: 'breadcrumb.setting',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'breadcrumb.zone',
        route: 'list-zone',
        resource: 'zone',
        action: 'read'
      },
      {
        title: 'breadcrumb.lot',
        route: 'list-lot',
        resource: 'lot',
        action: 'read'
      },
    ],
  },
];