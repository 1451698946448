export default [
  {
    title: 'breadcrumb.user',
    icon: 'UsersIcon',
    children: [
      {
        title: 'breadcrumb.user',
        route: 'list-user',
        icon: '',
        resource: 'user',
        action: 'read'
      },
      {
        title: 'breadcrumb.rolePermission',
        route: 'list-role-permission',
        icon: '',
        resource: 'role-permission',
        action: 'read'
      },
    ],
  },
];